import React, { useRef, useState, Suspense, useEffect } from 'react';
import { Canvas, useThree } from '@react-three/fiber';

import Marker, { MAX_MARKER_SIZE, MIN_MARKER_SIZE } from '../Marker';
import CameraControls from '../CameraControls';
import Controls from '../Controls';
import DandyMesh from '../DandyMesh';
import useKeyboardShortcuts from '../useKeyboardShortcuts';
import useOperationStack from '../useOperationStack';
import { Loading } from './Loading';
import CameraRig from './CameraRig';
import HelpKey from '../HelpKey';

import options from '../config.json'

function Raycaster(props) {
  const { raycaster } = useThree();

  useEffect(() => {
    raycaster.near = props.near;
  }, [props.near, raycaster]);

  return null;
}

function VertexPainter() {
  const [loadedAnnotationData, setLoadedAnnotationData] = useState({});
  const [annotationData, setAnnotationData] = useState({});
  const [geometry, setGeometry] = useState(null);
  const [faceIndex, setFaceIndex] = useState(null);
  const [cameraOptions, setCameraOptions] = useState({
    fov: 75,
    position: [-40, 30, 0],
    near: 1,
  });
  const [appearanceOptions, setAppearanceOptions] = useState({
    saturation: 0.0,
    ambientLightIntensity: 0.1,
    pointLightIntensity: 0.25,
    directionalLightIntensity: 0.3,
  });

  const mesh = useRef();
  const camera = useRef();
  const light = useRef();

  const [visibleLabels, setVisibleLabels] = useState(options.labels);
  const [label, setLabel] = useState(null);
  const [marker, setMarker] = useState(null);
  const [isSelecting, setIsSelecting] = useState(null);  // whether we are selecting a label via ctrl-click (i.e. holding down control)
  const [isShiftDown, setIsShiftDown] = useState(false); // whether we are holding shift
  const [hoverLabel, setHoverLabel] = useState(null);
  const [markerSize, setMarkerSize] = useState(4);

  const [mouseHover, setMouseHover] = useState(false);
  const meshPointerEvents = {
    onPointerOver: (event) => {
      setMouseHover(true);
    },
    onPointerOut: (event) => {
      setMouseHover(false);
    },
  };

  const [isFilterFocused, setIsFilterFocused] = useState(false);
  const [hideAllLabels, setHideAllLabels] = useState(false);
  useKeyboardShortcuts(['Digit0'], () => {setHideAllLabels(!hideAllLabels)});

  useKeyboardShortcuts(['Equal', 'NumpadAdd'], (code) => {
    if (markerSize === MAX_MARKER_SIZE) return;
    setMarkerSize(markerSize + 1);
  });

  useKeyboardShortcuts(['Minus', 'NumpadSubtract'], (code) => {
    if (markerSize === MIN_MARKER_SIZE) return;
    setMarkerSize(markerSize - 1);
  });

  function handleCameraChange() {
    if (camera.current && light.current) {
      const {x, y, z} = camera.current.position;
      light.current.position.set(x, y, z);
    }
  }

  useEffect(() => {
    handleCameraChange();
  });

  const activeMarkerHover = mouseHover && (marker != null || isSelecting);
  const markerGroup = marker ? marker.group : null;
  const markerId = marker ? marker.id : null;

  function calcOrderedVisibleLabels() {

    // Move active label to the end of the labels list,
    // this way restoreColorByMasks will render active
    // label on top
    const orderedVisibleLabels = visibleLabels.filter((l) => l.group !== markerGroup || l.id !== markerId);
    const visibleActiveLabel = visibleLabels.find((l) => l.group === markerGroup && l.id === markerId);
    visibleActiveLabel && orderedVisibleLabels.push(visibleActiveLabel);
    return orderedVisibleLabels;
  }

  const orderedVisibleLabels = calcOrderedVisibleLabels();

  const {
    saveSnapshot,
    firstDown, setFirstDown,
    isDirty, setIsDirty,
  } = useOperationStack({
    geometry, orderedVisibleLabels,
    annotationData, setAnnotationData,
  });

  // don't allow rotation if:
  // - we have a selected painting label, or
  // - we are holding ctrl to select a label on the mesh
  // and we are not holding shift (which bypasses the above)
  const noRotate = (label != null || isSelecting) && (!isShiftDown);

  return (
    <>
      <Controls
        mesh={mesh}
        geometry={geometry}
        setGeometry={setGeometry}
        setFaceIndex={setFaceIndex}

        setMarker={setMarker}

        {...{
          label, setLabel,
          visibleLabels, setVisibleLabels,
          hideAllLabels, setHideAllLabels,
          markerSize, setMarkerSize,
          cameraOptions, setCameraOptions,
          appearanceOptions, setAppearanceOptions,
          isFilterFocused, setIsFilterFocused,
          loadedAnnotationData, setLoadedAnnotationData,
          annotationData, setAnnotationData,
          saveSnapshot,
          isDirty, setIsDirty,
        }}
      />
      <HelpKey showModifier={isFilterFocused}/>

      <Canvas>
        <CameraControls onChange={handleCameraChange} noRotate={noRotate} rotateSpeed={4.} />
        <CameraRig cameraRef={camera} {...cameraOptions} />
        <Raycaster near={cameraOptions.near} />
        <ambientLight intensity={appearanceOptions.ambientLightIntensity} />
        <pointLight position={[0, 10, 0]} intensity={appearanceOptions.pointLightIntensity} />
        <pointLight position={[0, -30, 0]} intensity={appearanceOptions.pointLightIntensity} />
        <directionalLight
          ref={light}
          intensity={appearanceOptions.directionalLightIntensity} />

        <Suspense fallback={<Loading />}>
          <DandyMesh
            geometry={geometry}
            meshRef={mesh}
            {...meshPointerEvents}
          >
            <Marker
              marker={marker}
              markerGroup={markerGroup}
              markerId={markerId}
              visibleLabels={visibleLabels}
              orderedVisibleLabels={orderedVisibleLabels}
              visible={activeMarkerHover}
              saturation={appearanceOptions.saturation}
              hideAllLabels={hideAllLabels}
              setLabel={setLabel}
              erase={false}
              faceIndex={faceIndex}
              size={markerSize}
              alpha={false}
              isSelecting={isSelecting}
              setIsSelecting={setIsSelecting}
              isShiftDown={isShiftDown}
              setIsShiftDown={setIsShiftDown}
              hoverLabel={hoverLabel}
              setHoverLabel={setHoverLabel}
              saveSnapshot={saveSnapshot}
              firstDown={firstDown}
              setFirstDown={setFirstDown}
            ></Marker>
          </DandyMesh>
        </Suspense>
      </Canvas>
    </>
  );
}

export default VertexPainter;
